var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('editor',{attrs:{"plugins":"advlist autolink lists link image charmap anchor table preview","value":_vm.value,"tinymce-script-src":"/tinymce-6.0.3/tinymce.min.js","init":{
        height: this.height,
        skin: this.$vuetify.theme.dark ? 'oxide-dark' : undefined,
        language: this.$i18n.locale.split('-')[0],
        file_picker_types: 'image',
        menubar: true,
        menu: {
            file: { items: [] },
            view: { items: [] },
            insert: {
                title: 'Insert',
                items: 'image link inserttable | charmap',
            },
            format: {
                title: 'Format',
                items:
                    'bold italic underline strikethrough | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | removeformat',
            },
            table: {
                title: 'Table',
                items: 'inserttable | cell row column | tableprops deletetable',
            },
        },
        toolbar:
            'undo redo | blocks | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | \
                    preview |',
        branding: true,
        file_picker_callback: this.filePickerCallback,
        content_style: 'body { font-family: sans-serif; font-size: 10.5pt; color: black; background: white; }',
        browser_spellcheck: true,
        contextmenu: false,
        setup: this.setup,
        forced_root_block: 'div',
        paste_as_text: true,
    }},on:{"input":function($event){return _vm.$emit('input', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }